<div class="custom-dialog">
    <div class="dialog__header">
        <img src="assets/icons/checked.png" />
        <div class="dialog__header-title">
            {{ "dialogs.activateAccountSuccessHeader" | translate }}
        </div>
    </div>

    <div class="dialog__content">
        <span>{{ "dialogs.activateAccountSuccessDesc" | translate }}</span>
        <span
            ><a href="/login">{{ "dialogs.here" | translate }}</a></span
        >
    </div>

    <div class="dialog__footer"></div>

    <div class="dialog-close clickable" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </div>
</div>
