<div class="custom-dialog">
    <div class="dialog__header">
        <img src="assets/image/account-pending.svg" />
        <div class="dialog__header-title">
            {{ "dialogs.accountInvalidHeader" | translate }}
        </div>
    </div>

    <div class="dialog__content">
        <p class="dialog-text">
            {{ "dialogs.accountInvalidDesc1" | translate }}
        </p>
        <p class="dialog-text">
            {{ "dialogs.accountSupportDesc" | translate }}
        </p>
    </div>

    <div class="dialog__footer">
        <div class="dialog__buttons">
            <button app-whatsapp-support class="btn dialog-btn whatsapp-btn">
                <img src="assets/icons/whatsapp-icon.svg" />
                <span style="color: white"> {{ "dialogs.whatsappChat" | translate }}</span>
            </button>
        </div>
    </div>

    <div class="dialog-close clickable" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </div>
</div>
