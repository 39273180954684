import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { AuthGuardService } from "./share/service/auth-guard.service";
import { RegisterComponent } from "./authentication/register/register.component";
import { LoginComponent } from "./authentication/login/login.component";
import { ResetPasswordComponent } from "./authentication/reset-password/reset-password.component";

const routes: Routes = [
    {
        path: "",
        component: AppBlankComponent,
        // canActivate: [AuthGuardService],
        children: [
            {
                path: "",
                redirectTo: "/claims",
                pathMatch: "full",
            },
            {
                path: "",
                loadChildren: () => import("./claims/claims.module").then((m) => m.ClaimsModule),
            },
            {
                path: "account",
                canActivate: [AuthGuardService],
                loadChildren: () => import("./account/account.module").then((m) => m.AccountModule),
            },
            {
                path: "register",
                component: RegisterComponent,
            },
            {
                path: "login",
                component: LoginComponent,
            },
            {
                path: "reset-password",
                component: ResetPasswordComponent,
            },
        ],
    },
    {
        path: "",
        component: AppBlankComponent,
        children: [
            {
                path: "authentication",
                loadChildren: () =>
                    import("./authentication/authentication.module").then((m) => m.AuthenticationModule),
            },
        ],
    },
    {
        path: "**",
        redirectTo: "/claims",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
