import { NgModule } from "@angular/core";
import { BackPageDirective } from "./back-page.directive";
import { CallToDirective } from "./call-to.directive";
import { ScreenDirective } from "./device.directive";
import { DisableControlDirective } from "./disable-control.directive";
import { EmailToDirective } from "./email-to.directive";
import { WhatsAppToDirective } from "./ws-to.directive";
import { CustomerSupportWhatsappDirective } from "./customer-support-whatsapp.directive";

@NgModule({
    declarations: [
        EmailToDirective,
        CallToDirective,
        WhatsAppToDirective,
        ScreenDirective,
        BackPageDirective,
        DisableControlDirective,
        CustomerSupportWhatsappDirective,
    ],
    exports: [
        EmailToDirective,
        CallToDirective,
        WhatsAppToDirective,
        ScreenDirective,
        BackPageDirective,
        DisableControlDirective,
        CustomerSupportWhatsappDirective,
    ],
})
export class CustomDirectiveModule {}
