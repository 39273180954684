import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthenticationRoutingModule } from "./authentication/authentication-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "./material-module";
import { NgxMatIntlTelInputComponent } from "ngx-mat-intl-tel-input";
import { NgOtpInputModule } from "ng-otp-input";
import { NgxMaskModule } from "ngx-mask";
import { CountdownModule } from "ngx-countdown";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxSpinnerModule } from "ngx-spinner";
import { CustomDirectiveModule } from "./share/directive/custom-directive.module";

@NgModule({
    declarations: [],
    imports: [CommonModule, NgxMatIntlTelInputComponent],
    exports: [
        CommonModule,
        AuthenticationRoutingModule,
        FormsModule,
        CustomDirectiveModule,
        ReactiveFormsModule,
        MaterialModule,
        NgxMatIntlTelInputComponent,
        NgOtpInputModule,
        NgxSpinnerModule,
        NgxMaskModule,
        CountdownModule,
        FlexLayoutModule,
    ],
    providers: [],
})
export class SharedModule {}
