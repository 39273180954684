import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ActivateAccountComponent } from "./activate-account/activate-account.component";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { RegisterComponent } from "./register/register.component";

const routes: Routes = [
    // {
    // 	path: "login",
    // 	children: [
    // 		{
    // 			path: "",
    // 			component: LoginComponent,
    // 		},
    // 	],
    // },
    // {
    // 	path: "activate-account",
    // 	children: [
    // 		{
    // 			path: "",
    // 			component: ActivateAccountComponent,
    // 		},
    // 	],
    // },
    // {
    // 	path: "register",
    // 	children: [
    // 		{
    // 			path: "",
    // 			component: RegisterComponent,
    // 		},
    // 	],
    // },
    // {
    // 	path: "reset-password",
    // 	children: [
    // 		{
    // 			path: "",
    // 			component: ResetPasswordComponent,
    // 		},
    // 	],
    // },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
