import { Directive, Input, OnChanges } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: "[opDisabled]",
})
export class DisableControlDirective implements OnChanges {
    @Input() opDisabled;
    ngOnChanges(changes) {
        if (changes["opDisabled"]) {
            const action = this.opDisabled ? "disable" : "enable";
            this.ngControl.control[action]();
        }
    }

    constructor(private ngControl: NgControl) {}
}
