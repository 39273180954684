import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-account-invalid-dialog",
    templateUrl: "./account-invalid-dialog.component.html",
    styleUrls: ["./account-invalid-dialog.component.scss"],
})
export class AccountInvalidDialogComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
