<div class="otp__dialog">
    <div class="otp__header">
        <img src="assets/icons/otp.png" />
        <p class="otp__header-title">
            <b>{{ "common.otpVerification" | translate }}</b>
        </p>

        <p>
            {{ "common.otpSent" | translate }} <b>{{ _data.mobileNo }}</b>
        </p>
    </div>

    <div class="otp__input">
        <ng-otp-input #otpInput [config]="otpInputConfig" (onInputChange)="handeOtpChange($event)"> </ng-otp-input>
    </div>

    <div class="otp__footer">
        <div class="mt-md text-center">
            <span>{{ "common.noOtp" | translate }} </span>
            <countdown
                #countdown
                class="otp__footer-countdown"
                [class.hidden]="otpExpired"
                [config]="config"
                (event)="handleEvent($event)"></countdown>
            <span class="mt-xs" *ngIf="otpExpired">
                <button class="anchor-btn" (click)="handleResendOTP()">
                    {{ "common.resendOtp" | translate }}
                </button>
            </span>
        </div>

        <div class="mt-lg">
            <button
                class="primary-btn"
                [ngClass]="!otpIsValid ? 'disabled-btn' : ''"
                [disabled]="!otpIsValid"
                (click)="handleVerifyOTP()">
                {{ "common.verifyOtp" | translate }}
            </button>
        </div>
    </div>
</div>
