<div class="wrapper" (scroll)="scrollHandler($event)">
    <mat-sidenav-container style="position: absolute; top: 0; bottom: 0; left: 0; right: 0">
        <mat-sidenav #sidenav mode="over" [(opened)]="opened">
            <app-menu (closeMenu)="onMenuToggled($event)"></app-menu>
        </mat-sidenav>
        <mat-sidenav-content>
            <app-header [state]="headerState" [menuState]="opened" (toggleMenu)="onMenuToggled($event)"></app-header>
            <div class="page-container">
                <router-outlet></router-outlet>
                <!-- Claims Whatsaspp Fixed -->
            </div></mat-sidenav-content
        >
        <!-- <app-footer></app-footer> --></mat-sidenav-container
    >
</div>
