import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "inputError",
})
export class InputErrorPipe implements PipeTransform {
    transform(value: any): string {
        let rvalue: string;
        if (value) {
            if (value.required) {
                rvalue = "error.required";
            } else if (value.mask || value.invalid || value.nric || value.validatePhoneNumber || value.email) {
                rvalue = "error.invalid";
            }
        }
        return rvalue;
    }
}
