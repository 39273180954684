<mat-toolbar class="navbar" [ngClass]="{ 'not-top': state === 'not-top' }">
    <div class="navbar-container">
        <div class="navbar-logo" [routerLink]="isLoggedIn ? '/claims/dashboard' : '/claims'">
            <img
                src="https://storage.googleapis.com/ps-asset/rider-protection/partner-logo/shopeexpress-logo.png"
                alt="policy-street-claims-logo" />
        </div>
        <span fxFlex></span>
        <div class="desktop-actions">
            <button mat-flat-button [matMenuTriggerFor]="claims">
                <span>{{ "navigation.claims" | translate }}</span>
                <mat-icon>expand_more</mat-icon>
            </button>

            <button *ngIf="isLoggedIn" mat-flat-button (click)="handleNavigate('/claims/dashboard')">
                <span>{{ "navigation.dashboard" | translate }}</span>
            </button>

            <button mat-stroked-button [matMenuTriggerFor]="language" class="lang-dropdown">
                {{ "language." + translate.currentLang | translate }}
                <mat-icon>expand_more</mat-icon>
            </button>

            <button mat-icon-button class="navbar-menu" [matMenuTriggerFor]="profile">
                <mat-icon class="profile-icon">account_circle</mat-icon>
            </button>
        </div>

        <div class="mobile-actions">
            <button mat-flat-button (click)="openMenu()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>

<mat-menu #claims="matMenu">
    <button mat-menu-item (click)="handleNavigate('/claims-submit')">
        <mat-icon>assignment_add</mat-icon><span>{{ "navigation.makeAClaim" | translate }}</span>
    </button>
    <button *ngIf="!isLoggedIn" mat-menu-item (click)="handleNavigate('/claims')">
        <mat-icon>home</mat-icon><span>{{ "navigation.overview" | translate }}</span>
    </button>
    <button *ngIf="isLoggedIn" mat-menu-item (click)="handleNavigate('/claim-status')">
        <mat-icon>update</mat-icon><span>{{ "navigation.claimStatus" | translate }}</span>
    </button>
</mat-menu>

<mat-menu #language="matMenu">
    <button mat-menu-item (click)="switch('en')">
        <span>{{ "language.en" | translate }}</span>
    </button>
    <button mat-menu-item (click)="switch('bm')">
        <span>{{ "language.bm" | translate }}</span>
    </button>
</mat-menu>

<mat-menu #profile="matMenu">
    <button mat-menu-item (click)="handleNavigate('/account/profile')" *ngIf="isLoggedIn">
        <mat-icon>person</mat-icon><span>{{ "navigation.profile" | translate }}</span>
    </button>

    <button mat-menu-item (click)="handleLogout()" *ngIf="isLoggedIn">
        <mat-icon>logout</mat-icon><span>{{ "common.logout" | translate }}</span>
    </button>

    <button mat-menu-item (click)="handleNavigate('/login')" *ngIf="!isLoggedIn">
        <mat-icon>login</mat-icon><span>{{ "common.login" | translate }}</span>
    </button>
</mat-menu>
