export const STATE_ARRAY = [
    { value: "SGR", name: "Selangor" },
    { value: "KUL", name: "Kuala Lumpur" },
    { value: "JHR", name: "Johor" },
    { value: "KDH", name: "Kedah" },
    { value: "KTN", name: "Kelantan" },
    { value: "LBN", name: "Labuan" },
    { value: "MLK", name: "Melaka" },
    { value: "NSN", name: "Negeri Sembilan" },
    { value: "PHG", name: "Pahang" },
    { value: "PRK", name: "Perak" },
    { value: "PLS", name: "Perlis" },
    { value: "PNG", name: "Pulau Pinang" },
    { value: "PJY", name: "Putrajaya" },
    { value: "SBH", name: "Sabah" },
    { value: "SWK", name: "Sarawak" },
    { value: "TRG", name: "Terengganu" },
];
