<div class="mobile-menu">
    <button mat-icon-button class="dismiss-btn" (click)="dismissMenu()">
        <mat-icon>close</mat-icon>
    </button>

    <div class="menu-section">
        <div class="mobile-nav-title">
            <mat-icon>description</mat-icon>
            <span>{{ "navigation.claims" | translate }}</span>
        </div>

        <button
            *ngIf="isLoggedIn"
            mat-menu-item
            (click)="handleNavigate('/claims/dashboard')"
            [ngClass]="isActiveUrl('dashboard') ? 'active-menu-button' : ''">
            <mat-icon>dashboard</mat-icon><span>{{ "navigation.dashboard" | translate }}</span>
        </button>
        <button
            *ngIf="isLoggedIn"
            mat-menu-item
            (click)="handleNavigate('/claim-status')"
            [ngClass]="isActiveUrl('claim-status') ? 'active-menu-button' : ''">
            <mat-icon>update</mat-icon><span>{{ "navigation.claimStatus" | translate }}</span>
        </button>
        <button
            mat-menu-item
            (click)="handleNavigate('/claims-submit')"
            [ngClass]="isActiveUrl('claims-submit') ? 'active-menu-button' : ''">
            <mat-icon>assignment_add</mat-icon><span>{{ "navigation.makeAClaim" | translate }}</span>
        </button>
        <button
            *ngIf="!isLoggedIn"
            mat-menu-item
            (click)="handleNavigate('/claims')"
            [ngClass]="isActiveUrl('overview') ? 'active-menu-button' : ''">
            <mat-icon>home</mat-icon><span>{{ "navigation.overview" | translate }}</span>
        </button>
    </div>

    <div *ngIf="isLoggedIn" class="menu-section">
        <div class="mobile-nav-title"><mat-icon>settings</mat-icon>{{ "navigation.settings" | translate }}</div>

        <button
            mat-menu-item
            (click)="handleNavigate('/account/profile')"
            [ngClass]="isActiveUrl('profile') ? 'active-menu-button' : ''">
            <mat-icon>person</mat-icon><span>{{ "navigation.profile" | translate }}</span>
        </button>
    </div>

    <div class="menu-section">
        <div class="mobile-nav-title"><mat-icon>translate</mat-icon>{{ "navigation.language" | translate }}</div>

        <button mat-menu-item (click)="switch('en')" [ngClass]="selectedLanguage === 'en' ? 'active-menu-button' : ''">
            <span>{{ "language.en" | translate }}</span>
        </button>
        <button mat-menu-item (click)="switch('bm')" [ngClass]="selectedLanguage === 'bm' ? 'active-menu-button' : ''">
            <span>{{ "language.bm" | translate }}</span>
        </button>
    </div>

    <button class="outline-btn mt-sm" (click)="handleLogout()" *ngIf="isLoggedIn">
        <mat-icon>logout</mat-icon><span>{{ "common.logout" | translate }}</span>
    </button>

    <a class="primary-btn mt-sm" href="authentication/login" *ngIf="!isLoggedIn">
        <mat-icon>login</mat-icon><span>{{ "common.login" | translate }}</span>
    </a>
</div>
