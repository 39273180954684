import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { NgOtpInputComponent, NgOtpInputConfig } from "ng-otp-input";
import { CountdownConfig, CountdownEvent } from "ngx-countdown";
import { UserDataService } from "src/app/share/data-service/user.data.service";
import { ValidatorFunction } from "src/app/share/function/validator.function";
import { AlertService } from "src/app/share/service/alert.service";
import { AccountValidationMessages } from "../../share/interface/auth.interface";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
    @ViewChild("cd") cd: any;
    @ViewChild(NgOtpInputComponent, { static: false}) ngOtpInput: NgOtpInputComponent;

    hide: boolean = true;
    resetPasswordFormGroup: UntypedFormGroup;
    changePasswordFormGroup: UntypedFormGroup;
    otpFormGroup: UntypedFormGroup;
    mobileNo: string;
    password: string;
    otp: string = "";
    otpExpired: boolean = false;
    otpInputConfig: NgOtpInputConfig = {
        length: 6,
        inputClass: "otp-input",
    };
    otpIsValid: boolean = false;
    otpSent: boolean = false;
    isPasswordChanged: boolean = false;
    userId: string = "";
    displayedError: string;
    selectedLanguage: string = "";

    config: CountdownConfig = { leftTime: 120, format: "mm:ss", notify: [5] };

    account_validation_messages: Record<string, AccountValidationMessages[]> = {
        confirm_password: [{ type: "areEqual", message: "errors.passwordNotMatching" }],
        password: [
            {
                type: "minlength",
                message: "errors.passwordRequirement1",
            },
            {
                type: "pattern",
                message: "errors.passwordRequirement2",
            },
        ],
    };

    constructor(
        public translate: TranslateService,
        private userDataService: UserDataService,
        private alertService: AlertService,
    ) {
        if (localStorage.getItem("locale")) {
            translate.use(localStorage.getItem("locale"));
            this.selectedLanguage = localStorage.getItem("locale");
        }
    }
    switch(lang: string) {
        this.translate.use(lang);
        this.selectedLanguage = lang;
        localStorage.setItem("locale", lang);
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm() {
        this.resetPasswordFormGroup = new UntypedFormGroup({
            mobileNo: new UntypedFormControl(null, [Validators.required]),
        });

        this.changePasswordFormGroup = new UntypedFormGroup(
            {
                newPass: new UntypedFormControl(
                    "",
                    Validators.compose([
                        Validators.minLength(8),
                        Validators.required,
                        Validators.pattern("^(?=.*[!?@#$%^&+=]).*$"), //Special character required
                    ]),
                ),
                repeatPass: new UntypedFormControl("", Validators.required),
            },
            (formGroup: UntypedFormGroup) => {
                return ValidatorFunction.areEqual(formGroup);
            },
        );

        this.resetPasswordFormGroup.controls["mobileNo"].valueChanges.subscribe((x) => {
            this.displayedError = "";
        });
    }

    handeOtpChange(value: string): void {
        if (value.length === 6) {
            this.otp = value;
        } else {
            this.otp = "";
        }
    }

    handleEvent(e: CountdownEvent) {
        if (e.action === "notify") {
        }
        if (e.action === "done") {
            this.otpExpired = true;
        }
    }

    handleResetPassword() {
        if (this.resetPasswordFormGroup.invalid) return;
        this.displayedError = "";
        this.mobileNo = this.resetPasswordFormGroup.get("mobileNo").value;

        this.userDataService.sendOtpForResetPassword(this.mobileNo).subscribe({
            next: (x) => {
                this.otpSent = true;
            },
            error: (err) => {
                this.displayedError = "errors.accountNotFound";
            },
        });
    }

    handleChangePassword() {
        const mobileNo = this.mobileNo;
        const password = this.changePasswordFormGroup.get("newPass").value;
        this.userDataService.resetPassword(mobileNo, password).subscribe({
            next: (x) => {
                this.isPasswordChanged = true;
                this.translate.instant("common.passwordChangeSuccess");
            },
            error: (err) => {
                console.log(err);
            },
        });
    }

    handleVerifyOTP() {
        const encodedMobileNo = this.resetPasswordFormGroup.get("mobileNo").value;

        this.userDataService.verifyOtpForResetPassword(encodedMobileNo, this.otp).subscribe({
            next: (x) => {
                this.otpIsValid = true;
            },
            error: (err) => {
                this.alertService.openSnackBar("Invalid/Expired OTP");
                console.log(err);
            },
        });
    }

    handleResendOTP(): void {
        //Check valid information
        this.otpExpired = false;
        this.handleResetPassword();
        this.cd.restart();
        this.ngOtpInput.setValue("");
    }
}
