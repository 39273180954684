import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { UserDataService } from "src/app/share/data-service/user.data.service";
import { ValidatorFunction } from "src/app/share/function/validator.function";
import { OtpDialogComponent } from "../components/dialogs/otp-dialog/otp-dialog.component";
import { AccountActivatedDialogComponent } from "../components/dialogs/account-activated-dialog/account-activated-dialog.component";
import { STATE_ARRAY } from "src/app/share/constants/common";
import { AccountInvalidDialogComponent } from "../components/dialogs/account-invalid-dialog/account-invalid-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertService } from "src/app/share/service/alert.service";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
    riderVerificationFormGroup: UntypedFormGroup;
    accRegisterFormGroup: UntypedFormGroup;
    newPasswordFormGroup: UntypedFormGroup;
    otpIsVerified: boolean = false;
    mobileNo: string = "";
    identityNo: string = "";
    selectedLanguage: string = "";
    hide: boolean = true;
    state_list: { value: string; name: string }[] = STATE_ARRAY;

    account_validation_messages: any = {
        confirm_password: [{ type: "areEqual", message: "errors.passwordNotMatching" }],
        password: [
            {
                type: "minlength",
                message: "errors.passwordRequirement1",
            },
            {
                type: "pattern",
                message: "errors.passwordRequirement2",
            },
        ],
    };

    constructor(
        private ngxSpinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private router: Router,
        public translate: TranslateService,
        private userDataService: UserDataService,
        private _dialogRef: MatDialog,
        private alertService: AlertService,
    ) {
        if (localStorage.getItem("locale")) {
            translate.use(localStorage.getItem("locale"));
            this.selectedLanguage = localStorage.getItem("locale");
        }
    }

    switch(lang: string) {
        this.translate.use(lang);
        this.selectedLanguage = lang;
        localStorage.setItem("locale", lang);
    }

    ngOnInit(): void {
        this.initForm();
    }

    private initForm() {
        this.riderVerificationFormGroup = new UntypedFormGroup({
            mobileNo: new UntypedFormControl(this.mobileNo, [Validators.required]),
            identityNo: new UntypedFormControl(this.identityNo, [
                Validators.pattern("(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))([0-9]{2})([0-9]{4})"),
                Validators.required,
            ]),
        });

        this.accRegisterFormGroup = new UntypedFormGroup({
            mobileNo: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
            identityNo: new UntypedFormControl({ value: null, disabled: true }, [
                Validators.pattern("(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))([0-9]{2})([0-9]{4})"),
                Validators.required,
            ]),
            email: new UntypedFormControl(null, [Validators.email]),
            address1: new UntypedFormControl(null, [Validators.required]),
            address2: new UntypedFormControl(null),
            city: new UntypedFormControl(null, [Validators.required]),
            postcode: new UntypedFormControl(null, [Validators.required]),
            state: new UntypedFormControl(null, [Validators.required]),
        });

        this.newPasswordFormGroup = new UntypedFormGroup(
            {
                newPass: new UntypedFormControl(
                    "",
                    Validators.compose([
                        Validators.minLength(8),
                        Validators.required,
                        Validators.pattern("^(?=.*[!?@#$%^&+=]).*$"), //Special character required
                    ]),
                ),
                repeatPass: new UntypedFormControl("", Validators.required),
            },
            (formGroup: UntypedFormGroup) => {
                return ValidatorFunction.areEqual(formGroup);
            },
        );
    }

    handleVerifyRider() {
        this.ngxSpinnerService.show();
        this.mobileNo = this.riderVerificationFormGroup.get("mobileNo").value;
        this.identityNo = this.riderVerificationFormGroup.get("identityNo").value;

        this.userDataService.verifyRider(this.mobileNo, this.identityNo).subscribe({
            next: (x) => {
                this.ngxSpinnerService.hide();
                if (x) {
                    const dialog = this._dialogRef.open(OtpDialogComponent, {
                        data: {
                            mobileNo: this.mobileNo,
                            identityNo: this.identityNo,
                        },
                    });

                    dialog.afterClosed().subscribe((res) => {
                        this.otpIsVerified = res;
                        this.accRegisterFormGroup.get("mobileNo").setValue(this.mobileNo);
                        this.accRegisterFormGroup.get("identityNo").setValue(this.identityNo);
                    });
                } else {
                    this._dialogRef.open(AccountInvalidDialogComponent);
                }
            },
            error: (err) => {
                this.ngxSpinnerService.hide();
                this._dialogRef.open(AccountInvalidDialogComponent);
            },
        });
    }

    handleRegisterAccount() {
        this.ngxSpinnerService.show();

        const mobileNo = this.accRegisterFormGroup.get("mobileNo").value;
        const email = this.accRegisterFormGroup.get("email").value;
        const identityNo = this.accRegisterFormGroup.get("identityNo").value;
        const address1 = this.accRegisterFormGroup.get("address1").value;
        const address2 = this.accRegisterFormGroup.get("address2").value;
        const state = this.accRegisterFormGroup.get("state").value;
        const city = this.accRegisterFormGroup.get("city").value;
        const postcode = this.accRegisterFormGroup.get("postcode").value;
        const password = this.newPasswordFormGroup.get("newPass").value;

        this.userDataService
            .registerRider(mobileNo, email, identityNo, password, address1, address2, postcode, city, state)
            .subscribe({
                next: (x) => {
                    this.ngxSpinnerService.hide();
                    if (x) {
                        const dialog = this._dialogRef.open(AccountActivatedDialogComponent);
                        dialog.afterClosed().subscribe(() => {
                            this.router.navigate(["/login"]);
                        });
                    } else {
                        this.alertService.openSnackBar(
                            "Failed to register account. Please try again later or contact support.",
                        );
                    }
                },
                error: (err) => {
                    // console.log(err);
                    this.ngxSpinnerService.hide();
                    this.alertService.openSnackBar(
                        "Failed to register account. Please try again later or contact support.",
                    );
                },
            });
    }

    getIdentityNoMasking() {
        return "000000000000";
    }

    getPostcodeMasking() {
        return "00000";
    }
}
