import { Directive, HostListener } from "@angular/core";

@Directive({
    selector: "[app-whatsapp-support]",
})
export class CustomerSupportWhatsappDirective {
    @HostListener("click")
    onClick() {
        const whatsappNumber = 60143703130;
        const entityName = "Policystreet";
        const whatsappContent = `Welcome to ${entityName}! How may we help you?`;

        let url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
            whatsappContent,
        )}&source=&data=&app_absent=`;

        window.open(url, "_blank");
    }
}
