import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-account-pending-dialog",
    templateUrl: "./account-pending-dialog.component.html",
    styleUrls: ["./account-pending-dialog.component.scss"],
})
export class AccountPendingDialogComponent {
    mobileNo: string;

    constructor(
        private _dialogRef: MatDialogRef<AccountPendingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: { mobileNo: string },
    ) {
        this.mobileNo = data.mobileNo;
    }
}
