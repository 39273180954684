import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "docname",
})
export class DocnamePipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        return null;
    }
}
