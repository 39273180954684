import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs";
import { jwtDecode } from "jwt-decode";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private basePath: string = `${environment.url}/api/User/`;
    private tokenKey = "token";

    loggedIn: boolean = false;

    constructor(private http: HttpClient) {}
    setToken(token: string) {
        document.cookie = `${this.tokenKey}=${token};path=/;`;
    }
    logout() {
        document.cookie = `${this.tokenKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        window.location.reload();
        window.location.href = "/claims";
    }

    getToken() {
        return this.getCookie(this.tokenKey);
    }

    getUserId() {
        let token = this.getToken();
        let decoded = jwtDecode(token);
        return decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];
    }

    isTokenExpired(): boolean {
        let token = this.getToken();
        if (!token) return false;
        let decoded = jwtDecode(token);
        let expireTime = new Date(decoded["exp"] * 1000);

        return expireTime < new Date();
    }

    isLoggedIn(): boolean {
        if (this.getToken()) {
            return !this.isTokenExpired();
        }
        return false;
    }

    private getCookie(cname): string | null {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }
}
