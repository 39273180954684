import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/share/service/auth.service";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})
export class AppHeaderComponent {
    @Input() state = "";
    @Output() toggleMenu = new EventEmitter<{ isOpened: boolean }>();
    @Input() menuState: boolean = false;
    isLoggedIn: boolean = false;

    constructor(public translate: TranslateService, private router: Router, private authService: AuthService) {
        if (localStorage.getItem("locale")) {
            translate.use(localStorage.getItem("locale"));
        }

        if (!translate.currentLang) {
            translate.currentLang = "bm";
        }

        this.isLoggedIn = this.authService.getToken() ? true : false;
    }

    openMenu() {
        this.menuState = !this.menuState;
        this.toggleMenu.emit({ isOpened: this.menuState });
    }
    switch(lang: string) {
        this.translate.use(lang);
        localStorage.setItem("locale", lang);
    }

    handleLogout() {
        this.authService.logout();
    }

    handleNavigate(route: string) {
        if (this.router.url === route) {
            window.location.reload();
        } else {
            this.router.navigate([route]);
        }
    }
}
