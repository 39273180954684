<div class="wrapper" (scroll)="scrollHandler($event)">
    <mat-sidenav-container class="nav">
        <mat-sidenav #sidenav mode="over" [(opened)]="opened">
            <app-menu (closeMenu)="onMenuToggled($event)"></app-menu>
        </mat-sidenav>
        <mat-sidenav-content>
            <app-header [state]="headerState" [menuState]="opened" (toggleMenu)="onMenuToggled($event)"></app-header>
            <div class="page-container">
                <router-outlet></router-outlet>
                <!-- Claims Whatsaspp Fixed -->
                <div class="whatsapp--fixed clickable">
                    <img app-whatsapp-support src="assets/image/whatsapp-icon.png" alt="" />
                </div></div
        ></mat-sidenav-content>
    </mat-sidenav-container>
</div>
