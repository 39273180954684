import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-account-activated-dialog",
    templateUrl: "./account-activated-dialog.component.html",
    styleUrls: ["./account-activated-dialog.component.scss"],
})
export class AccountActivatedDialogComponent {
    constructor() {}
}
