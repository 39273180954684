<div class="auth-page">
    <div class="auth-container">
        <!-- Claims Left Column -->
        <div class="auth-hero__column">
            <div class="mobile-content">
                <div class="header">
                    <img
                        style="cursor: pointer"
                        routerLink="/"
                        src="https://storage.googleapis.com/ps-asset/rider-protection/partner-logo/shopeexpress-logo.png" />

                    <div>
                        <span>{{ selectedLanguage | uppercase }}</span>
                        <button mat-icon-button [matMenuTriggerFor]="language">
                            <mat-icon>language</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="auth-hero__content">
                <div class="auth-hero__title">
                    {{ "copywriting.loginHeroHeader" | translate }}
                </div>
                <div class="auth-hero__desc mt-sm">
                    {{ "copywriting.loginHeroSubheader" | translate }}
                </div>
            </div>
        </div>

        <!-- Claims Right Column -->
        <div class="auth-form__column">
            <div class="desktop-content">
                <div class="header">
                    <img
                        style="cursor: pointer"
                        routerLink="/"
                        src="https://storage.googleapis.com/ps-asset/rider-protection/partner-logo/shopeexpress-logo.png" />

                    <div>
                        <span>{{ selectedLanguage | uppercase }}</span>
                        <button mat-icon-button [matMenuTriggerFor]="language">
                            <mat-icon>language</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="auth-form__column-header">
                <img class="auth-form-logo" src="assets/icons/motor-icon.svg" />
                <div class="auth-form__title mdTitle-f mb-md">
                    {{ "common.loginHeroHeader" | translate }}
                </div>
            </div>

            <ng-container [ngTemplateOutlet]="loginTemplate"></ng-container>
        </div>
    </div>
</div>

<ng-template #loginTemplate>
    <div class="auth-form__container">
        <form class="mb-lg" #loginTemplateForm [formGroup]="loginFormGroup" (ngSubmit)="handleLogin()">
            <div class="form-field">
                <mat-form-field appearance="outline" hideRequiredMarker>
                    <mat-label>{{ "common.mobileNo" | translate }}</mat-label>
                    <ngx-mat-intl-tel-input [onlyCountries]="['my']" formControlName="mobileNo">
                    </ngx-mat-intl-tel-input>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>{{ "common.password" | translate }}</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" required />
                    <button
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="hide = !hide"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                    </button>
                </mat-form-field>
                <div *ngIf="errorMessage" class="mb-md">
                    <mat-error>{{ errorMessage | translate }}</mat-error>
                </div>
                <div style="width: 100%; text-align: end">
                    <a class="color-primary anchor-btn" href="/reset-password">
                        {{ "common.forgotPassword" | translate }}
                    </a>
                </div>

                <div class="mt-lg mb-sm">
                    <button
                        class="primary-btn"
                        type="submit"
                        [ngClass]="loginFormGroup.invalid ? 'disabled-btn' : ''"
                        [disabled]="loginFormGroup.invalid">
                        {{ "common.login" | translate }}
                    </button>
                </div>

                <div class="divider"></div>
                <div class="mt-sm">
                    <div class="fx-row fx-jc-sb">
                        <p>{{ "common.registerHere" | translate }}</p>
                        <a href="/register">{{ "common.register" | translate }}</a>
                    </div>
                    <div class="fx-row fx-jc-sb">
                        <p class="text-left">
                            {{ "common.claimForOthers" | translate }}
                        </p>
                        <a href="/claims">{{ "common.clickHere" | translate }}</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<mat-menu #language="matMenu">
    <button mat-menu-item (click)="switch('en')">
        <span>{{ "language.en" | translate }}</span>
    </button>
    <button mat-menu-item (click)="switch('bm')">
        <span>{{ "language.bm" | translate }}</span>
    </button>
</mat-menu>
