import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "src/app/share/service/auth.service";

@Component({
    selector: "app-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
})
export class MenuComponent {
    isLoggedIn: boolean = false;
    @Output() closeMenu = new EventEmitter<{ isOpened: boolean }>();
    currentRoute: string = "";
    selectedLanguage: string = "";

    constructor(public translate: TranslateService, private router: Router, private authService: AuthService) {
        if (localStorage.getItem("locale")) {
            translate.use(localStorage.getItem("locale"));
            this.selectedLanguage = localStorage.getItem("locale");
        }

        this.isLoggedIn = this.authService.getToken() ? true : false;
        this.currentRoute = this.router.url;
    }

    dismissMenu() {
        this.closeMenu.emit({ isOpened: false });
    }

    switch(lang: string) {
        this.translate.use(lang);
        this.selectedLanguage = lang;
        localStorage.setItem("locale", lang);
    }

    handleLogout() {
        this.authService.logout();
    }

    handleNavigate(route: string) {
        this.router.navigate([route]);
        this.dismissMenu();
    }

    isActiveUrl(pageName: string) {
        if (pageName === "overview" && this.router.url !== "/claims") {
            return false;
        }

        return this.router.url.includes(pageName);
    }
}
