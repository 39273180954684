import { Component, HostListener } from "@angular/core";

@Component({
    selector: "app-full",
    templateUrl: "./full.component.html",
    styleUrls: [],
})
export class AppFullComponent {
    headerState = "";
    opened: boolean = false;

    @HostListener("window:scroll", ["$event"]) // for window scroll events
    scrollHandler(event) {
        if (document.documentElement.scrollTop < 10) {
            this.headerState = "";
        } else {
            this.headerState = "not-top";
        }
    }

    onMenuToggled(eventData: { isOpened: boolean }) {
        this.opened = eventData.isOpened;
    }
}
