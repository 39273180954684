export const AUTH_MODE = {
    login: "login",
    createAccount: "createAccount",
    activateAccount: "activateAccount",
    resetPassword: "resetPassword",
};

export const AUTH_TABS = {
    login: "login",
    activate: "activate",
    resetPassword: "resetPassword",
    changePassword: "changePassword",
    otpActivate: "otpActivate",
    otpCreate: "otpCreate",
    otpReset: "otpReset",
};
