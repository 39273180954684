import { NgModule } from "@angular/core";
import { InputErrorPipe } from "./input-error.pipe";
import { DocnamePipe } from "./docname.pipe";

@NgModule({
    imports: [],
    declarations: [InputErrorPipe, DocnamePipe],
    exports: [InputErrorPipe],
})
export class CustomPipeModule {}
