import { Component, HostListener } from "@angular/core";
import { Subject } from "rxjs";

@Component({
    selector: "app-full-with-support",
    templateUrl: "./full-with-support.component.html",
    styleUrls: ["./full-with-support.component.scss"],
})
export class FullWithSupportComponent {
    headerState = "";
    opened: boolean = false;

    @HostListener("window:scroll", ["$event"]) // for window scroll events
    scrollHandler(event) {
        if (document.documentElement.scrollTop < 10) {
            this.headerState = "";
        } else {
            this.headerState = "not-top";
        }
    }

    onMenuToggled(eventData: { isOpened: boolean }) {
        this.opened = eventData.isOpened;
    }
}
