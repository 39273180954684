import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AUTH_MODE, AUTH_TABS } from "src/app/share/constants/auth";
import { UserDataService } from "src/app/share/data-service/user.data.service";
import { AuthService } from "src/app/share/service/auth.service";
import { AlertService } from "../../share/service/alert.service";
import { AccountActivatedDialogComponent } from "../components/dialogs/account-activated-dialog/account-activated-dialog.component";
import { AccountPendingDialogComponent } from "../components/dialogs/account-pending-dialog/account-pending-dialog.component";

@Component({
    selector: "app-login",
    styleUrls: ["./login.component.scss"],
    templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
    @ViewChild("cd") cd: any;
    @ViewChild("loginTemplateForm") loginTemplateForm;

    hide: boolean = true;
    loginFormGroup: UntypedFormGroup;
    mobileNo: string;
    password: string;
    tab: string = "login";
    mode: string = "login";
    errorMessage: string = "";
    selectedLanguage: string = "";

    constructor(
        private ngxSpinnerService: NgxSpinnerService,
        private alertService: AlertService,
        private authService: AuthService,
        public translate: TranslateService,
        private userDataService: UserDataService,
        private dialog: MatDialog,
    ) {
        if (localStorage.getItem("locale")) {
            translate.use(localStorage.getItem("locale"));
            this.selectedLanguage = localStorage.getItem("locale");
        }
    }

    switch(lang: string) {
        this.translate.use(lang);
        this.selectedLanguage = lang;
        localStorage.setItem("locale", lang);
    }

    ngOnInit(): void {
        this.initForm();
        this.tab = AUTH_TABS.login;
        this.mode = AUTH_MODE.login;
    }

    private initForm() {
        this.loginFormGroup = new UntypedFormGroup({
            mobileNo: new UntypedFormControl(null, [Validators.required]),
            password: new UntypedFormControl(null, [Validators.required]),
        });

        this.loginFormGroup.controls["mobileNo"].valueChanges.subscribe((x) => {
            this.errorMessage = "";
        });
        this.loginFormGroup.controls["password"].valueChanges.subscribe((x) => {
            this.errorMessage = "";
        });
    }

    handleLogin() {
        if (this.loginFormGroup.invalid) return;
        let value = this.loginFormGroup.value;

        this.ngxSpinnerService.show();

        this.userDataService.login(value.mobileNo, value.password).subscribe({
            next: (x) => {
                this.ngxSpinnerService.hide();
                this.authService.setToken(x);
                this.userDataService.getProfileDetails().subscribe({
                    next: (x) => {
                        if (!x.address) {
                            location.href = "/account/setup";
                        } else {
                            location.href = "/claims/dashboard";
                        }

                        this.ngxSpinnerService.hide();
                    },
                    error: (err) => {
                        this.ngxSpinnerService.hide();
                    },
                });
            },
            error: (err) => {
                if (err.error === "invalidCredentials" || err.error === "accountNotFound") {
                    this.errorMessage = "errors.invalidCredentials";
                } else if (err.error === "accountPendingVerify") {
                    this.dialog.open(AccountPendingDialogComponent, {
                        data: { mobileNo: this.mobileNo },
                    });
                } else {
                    this.errorMessage = "errors.invalidCredentials";
                }
                this.ngxSpinnerService.hide();
            },
        });
    }

    handleCreateNewAccount() {
        this.tab = AUTH_TABS.otpCreate;
        this.mode = AUTH_MODE.createAccount;
    }

    handleChangeTab(tabName: string) {
        this.tab = tabName;
    }
}
