<div class="auth-page">
    <div class="auth-container">
        <!-- Claims Left Column -->
        <div class="auth-hero__column">
            <div class="mobile-content">
                <div class="header">
                    <img
                        style="cursor: pointer"
                        routerLink="/"
                        src="https://storage.googleapis.com/ps-asset/rider-protection/partner-logo/shopeexpress-logo.png" />

                    <div>
                        <span>{{ selectedLanguage | uppercase }}</span>
                        <button mat-icon-button [matMenuTriggerFor]="language">
                            <mat-icon>language</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="auth-hero__content">
                <div class="auth-hero__title" style="max-width: 350px">
                    {{ "copywriting.loginHeroHeader" | translate }}
                </div>
                <div class="auth-hero__desc mt-sm" style="max-width: 450px">
                    {{ "copywriting.loginHeroSubheader" | translate }}
                </div>
            </div>
        </div>

        <!-- Claims Right Column -->
        <div class="auth-form__column">
            <div class="desktop-content">
                <div class="header">
                    <img
                        style="cursor: pointer"
                        routerLink="/"
                        src="https://storage.googleapis.com/ps-asset/rider-protection/partner-logo/shopeexpress-logo.png" />

                    <div>
                        <span>{{ selectedLanguage | uppercase }}</span>
                        <button mat-icon-button [matMenuTriggerFor]="language">
                            <mat-icon>language</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="auth-form__column-header">
                <img class="auth-form-logo" src="assets/icons/motor-icon.svg" />
                <div class="mdTitle-f mb-md">
                    {{ "authentication.registerAccount" | translate }}
                </div>
                <mat-divider></mat-divider>
            </div>

            <!-- First step: Verify mobile number and identity no. -->
            <ng-container *ngIf="!otpIsVerified" [ngTemplateOutlet]="riderVerificationTemplate"></ng-container>

            <!-- Second step: Account registration form -->
            <ng-container *ngIf="otpIsVerified" [ngTemplateOutlet]="accountRegistrationTemplate"></ng-container>
        </div>
    </div>
</div>

<ng-template #riderVerificationTemplate>
    <div class="auth-form__container">
        <div>
            <p>
                {{ "authentication.registerInfo" | translate }}
            </p>
        </div>

        <form [formGroup]="riderVerificationFormGroup" (ngSubmit)="handleVerifyRider()">
            <div class="form-field">
                <mat-form-field appearance="outline" hideRequiredMarker>
                    <mat-label>{{ "profileSettings.mobileNo" | translate }}</mat-label>
                    <ngx-mat-intl-tel-input
                        [preferredCountries]="['my']"
                        [onlyCountries]="['my']"
                        formControlName="mobileNo">
                    </ngx-mat-intl-tel-input>
                </mat-form-field>

                <mat-form-field appearance="outline" hideRequiredMarker>
                    <mat-label>{{ "profileSettings.identityNo" | translate }}</mat-label>
                    <input matInput formControlName="identityNo" [mask]="getIdentityNoMasking()" />
                </mat-form-field>
            </div>
            <div class="mt-lg fx-col fx-ac-c">
                <button
                    class="primary-btn"
                    [ngClass]="riderVerificationFormGroup.invalid ? 'disabled-btn' : ''"
                    [disabled]="riderVerificationFormGroup.invalid">
                    {{ "common.register" | translate }}
                </button>

                <a class="btn text-btn mt-sm" href="/login">{{ "common.returnToLogin" | translate }}</a>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #accountRegistrationTemplate>
    <div class="auth-form__container">
        <form style="width: 100%" [formGroup]="accRegisterFormGroup">
            <mat-form-field appearance="outline">
                <mat-label>{{ "common.mobileNo" | translate }}</mat-label>
                <ngx-mat-intl-tel-input
                    [preferredCountries]="['my']"
                    [onlyCountries]="['my']"
                    formControlName="mobileNo">
                </ngx-mat-intl-tel-input>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ "profileSettings.identityNo" | translate }}</mat-label>
                <input matInput formControlName="identityNo" [mask]="getIdentityNoMasking()" [maxlength]="12" />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ "profileSettings.email" | translate }}</mat-label>
                <input matInput formControlName="email" />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ "profileSettings.address1" | translate }}</mat-label>
                <input matInput formControlName="address1" />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>{{ "profileSettings.address2" | translate }}</mat-label>
                <input matInput formControlName="address2" />
            </mat-form-field>

            <div style="display: flex; gap: 8px">
                <mat-form-field appearance="outline">
                    <mat-label>{{ "profileSettings.state" | translate }}</mat-label>
                    <mat-select formControlName="state">
                        <mat-option *ngFor="let state of state_list" value="{{ state.value }}">{{
                            state.name
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ "profileSettings.postcode" | translate }}</mat-label>
                    <input matInput formControlName="postcode" [mask]="getPostcodeMasking()" />
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>{{ "profileSettings.city" | translate }}</mat-label>
                <input matInput formControlName="city" />
            </mat-form-field>
        </form>

        <!-- Password validation form -->
        <form [formGroup]="newPasswordFormGroup" (ngSubmit)="handleRegisterAccount()">
            <mat-form-field appearance="outline" hideRequiredMarker>
                <mat-label>{{ "common.newPassword" | translate }}</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="newPass" />
                <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                </button>
            </mat-form-field>

            <mat-form-field appearance="outline" hideRequiredMarker>
                <mat-label>{{ "common.repeatPassword" | translate }}</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="repeatPass" />
                <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                </button>
            </mat-form-field>

            <div style="display: flex; flex-direction: column">
                <mat-error *ngFor="let validation of account_validation_messages.confirm_password">
                    <mat-error
                        *ngIf="
                            (newPasswordFormGroup.get('repeatPass').hasError(validation.type) ||
                                newPasswordFormGroup.hasError(validation.type)) &&
                            (newPasswordFormGroup.get('repeatPass').dirty ||
                                newPasswordFormGroup.get('repeatPass').touched)
                        "
                        >{{ validation.message | translate }}</mat-error
                    >
                </mat-error>

                <mat-error *ngFor="let validation of account_validation_messages.password">
                    <mat-error
                        *ngIf="
                            newPasswordFormGroup.get('newPass').hasError(validation.type) &&
                            (newPasswordFormGroup.get('newPass').dirty || newPasswordFormGroup.get('newPass').touched)
                        "
                        >{{ validation.message | translate }}</mat-error
                    >
                </mat-error>
            </div>

            <div class="mt-lg">
                <button
                    class="primary-btn mb-md"
                    [ngClass]="accRegisterFormGroup.invalid || newPasswordFormGroup.invalid ? 'disabled-btn' : ''"
                    [disabled]="accRegisterFormGroup.invalid || newPasswordFormGroup.invalid">
                    {{ "common.register" | translate }}
                </button>
            </div>
        </form>
    </div>
</ng-template>

<mat-menu #language="matMenu">
    <button mat-menu-item (click)="switch('en')">
        <span>{{ "language.en" | translate }}</span>
    </button>
    <button mat-menu-item (click)="switch('bm')">
        <span>{{ "language.bm" | translate }}</span>
    </button>
</mat-menu>
