<div class="auth-page">
    <div class="auth-container">
        <!-- Claims Left Column -->
        <div class="auth-hero__column">
            <div class="mobile-content">
                <div class="header">
                    <img
                        style="cursor: pointer"
                        routerLink="/"
                        src="https://storage.googleapis.com/ps-asset/rider-protection/partner-logo/shopeexpress-logo.png" />

                    <div>
                        <span>{{ selectedLanguage | uppercase }}</span>
                        <button mat-icon-button [matMenuTriggerFor]="language">
                            <mat-icon>language</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="auth-hero__content">
                <div class="auth-hero__title" style="max-width: 350px">
                    {{ "copywriting.loginHeroHeader" | translate }}
                </div>
                <div class="auth-hero__desc mt-sm" style="max-width: 450px">
                    {{ "copywriting.loginHeroSubheader" | translate }}
                </div>
            </div>
        </div>

        <!-- Claims Right Column -->
        <div class="auth-form__column">
            <div class="desktop-content">
                <div class="header">
                    <img
                        style="cursor: pointer"
                        routerLink="/"
                        src="https://storage.googleapis.com/ps-asset/rider-protection/partner-logo/shopeexpress-logo.png" />

                    <div>
                        <span>{{ selectedLanguage | uppercase }}</span>
                        <button mat-icon-button [matMenuTriggerFor]="language">
                            <mat-icon>language</mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="!otpIsValid; else changePasswordTemplate">
                <div *ngIf="!otpSent; else otpTemplate">
                    <ng-container [ngTemplateOutlet]="resetPasswordTemplate"></ng-container>
                </div>
            </div>
        </div>

        <div class="whatsapp--fixed clickable">
            <img app-whatsapp-support src="assets/image/whatsapp-icon.png" alt="" />
        </div>
    </div>
</div>

<ng-template #resetPasswordTemplate>
    <div class="auth-form__container">
        <form [formGroup]="resetPasswordFormGroup" (ngSubmit)="handleResetPassword()">
            <div class="form-field">
                <div class="auth-form__column-header">
                    <img class="auth-form-logo" src="assets/icons/motor-icon.svg" />
                    <div class="mdTitle-f mb-md">
                        {{ "common.resetPassword" | translate }}
                    </div>
                    <div>
                        {{ "common.resetPasswordInstructions" | translate }}
                    </div>
                </div>

                <div class="form-fields mt-lg">
                    <mat-form-field appearance="outline" hideRequiredMarker>
                        <mat-label> {{ "common.mobileNo" | translate }}</mat-label>
                        <ngx-mat-intl-tel-input
                            [preferredCountries]="['my']"
                            [onlyCountries]="['my']"
                            formControlName="mobileNo">
                        </ngx-mat-intl-tel-input>
                    </mat-form-field>
                    <div class="error-box" *ngIf="displayedError">
                        {{ "errors.accountNotFound" | translate }}
                    </div>

                    <div class="mt-lg">
                        <button
                            class="primary-btn mb-md"
                            type="submit"
                            [ngClass]="resetPasswordFormGroup.invalid ? 'disabled-btn' : ''"
                            [disabled]="resetPasswordFormGroup.invalid">
                            {{ "common.resetPassword" | translate }}
                        </button>
                        <a class="color-primary anchor-btn" style="text-align: center" href="/login">
                            {{ "common.returnToLogin" | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #changePasswordTemplate>
    <div class="auth-form__container">
        <form [formGroup]="changePasswordFormGroup" (ngSubmit)="handleChangePassword()">
            <div class="form-field">
                <div *ngIf="!isPasswordChanged; else passwordChanged">
                    <div class="login-header">
                        <img class="login-logo" src="assets/icons/motor-icon.svg" />
                        <div class="mdTitle-f mb-md">
                            {{ "common.resetPassword" | translate }}
                        </div>
                    </div>

                    <form [formGroup]="changePasswordFormGroup" (ngSubmit)="handleChangePassword()" style="width: 100%">
                        <mat-form-field appearance="outline" hideRequiredMarker>
                            <mat-label> {{ "common.newPassword" | translate }}</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" formControlName="newPass" />
                            <button
                                type="button"
                                mat-icon-button
                                matSuffix
                                (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                                <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                            </button>
                        </mat-form-field>

                        <mat-form-field appearance="outline" hideRequiredMarker>
                            <mat-label> {{ "common.repeatPassword" | translate }}</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" formControlName="repeatPass" />
                            <button
                                type="button"
                                mat-icon-button
                                matSuffix
                                (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                                <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                            </button>
                        </mat-form-field>

                        <div style="display: flex; flex-direction: column">
                            <mat-error *ngFor="let validation of account_validation_messages.confirm_password">
                                <mat-error
                                    *ngIf="
                                        (changePasswordFormGroup.get('repeatPass').hasError(validation.type) ||
                                            changePasswordFormGroup.hasError(validation.type)) &&
                                        (changePasswordFormGroup.get('repeatPass').dirty ||
                                            changePasswordFormGroup.get('repeatPass').touched)
                                    "
                                    >{{ validation.message | translate }}</mat-error
                                >
                            </mat-error>

                            <mat-error *ngFor="let validation of account_validation_messages.password">
                                <mat-error
                                    *ngIf="
                                        changePasswordFormGroup.get('newPass').hasError(validation.type) &&
                                        (changePasswordFormGroup.get('newPass').dirty ||
                                            changePasswordFormGroup.get('newPass').touched)
                                    "
                                    >{{ validation.message | translate }}</mat-error
                                >
                            </mat-error>
                        </div>

                        <div class="mt-lg">
                            <button
                                class="primary-btn mb-md"
                                [ngClass]="changePasswordFormGroup.invalid ? 'disabled-btn' : ''"
                                [disabled]="changePasswordFormGroup.invalid">
                                {{ "common.updatePassword" | translate }}
                            </button>
                        </div>
                    </form>
                </div>

                <ng-template #passwordChanged>
                    <div class="login-header">
                        <img class="login-logo" src="assets/icons/motor-icon.svg" />
                        <div class="mdTitle-f mb-md mt-lg" style="text-align: center">
                            {{ "common.passwordChangeSuccess" | translate }}
                        </div>
                        <a class="color-primary anchor-btn" style="text-align: center" href="/login">
                            {{ "common.returnToLogin" | translate }}
                        </a>
                    </div>
                </ng-template>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #otpTemplate let-title="title">
    <div class="auth-form__container">
        <div class="form-field">
            <div class="login-header mb-lg">
                <img class="login-logo" src="assets/icons/motor-icon.svg" />
                <div class="mdTitle-f mb-md">
                    {{ "common.resetPassword" | translate }}
                </div>
                <div>
                    {{ "common.otpSent" | translate }}
                    <span class="fw-b">{{ mobileNo }}</span>
                </div>
            </div>

            <div class="form-fields">
                <div class="mb-sm">
                    {{ "common.otpInstructions" | translate }}
                </div>
                <ng-otp-input [config]="otpInputConfig" (onInputChange)="handeOtpChange($event)"> </ng-otp-input>

                <div class="mt-md">
                    <countdown
                        class="countdown"
                        [class.hidden]="otpExpired"
                        #cd
                        [config]="config"
                        (event)="handleEvent($event)"></countdown>
                    <div class="mt-xs" *ngIf="otpExpired">
                        {{ "common.noOtp" | translate }}
                        <button class="anchor-btn" (click)="handleResendOTP()">
                            {{ "common.resendOtp" | translate }}
                        </button>
                    </div>
                </div>

                <div class="mt-lg">
                    <button
                        class="primary-btn mb-md"
                        [ngClass]="otp.length < 6 ? 'disabled-btn' : ''"
                        [disabled]="otp.length < 6"
                        (click)="handleVerifyOTP()">
                        {{ "common.verifyOtp" | translate }}
                    </button>
                    <a class="color-primary anchor-btn" style="text-align: center" href="/login">
                        {{ "common.returnToLogin" | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<mat-menu #language="matMenu">
    <button mat-menu-item (click)="switch('en')">
        <span>{{ "language.en" | translate }}</span>
    </button>
    <button mat-menu-item (click)="switch('bm')">
        <span>{{ "language.bm" | translate }}</span>
    </button>
</mat-menu>
