import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppFullComponent } from "./layouts/full/full.component";
import { AppHeaderComponent } from "./layouts/header/header.component";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { HttpRequestInterceptor } from "./share/service/http.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CustomPipeModule } from "./share/pipe/custom-pipe.module";
import { FooterComponent } from "./layouts/footer/footer.component";
import { NgxMaskModule } from "ngx-mask";
import { MenuComponent } from "./layouts/menu/menu.component";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { AuthService } from "./share/service/auth.service";
import { AuthGuardService } from "./share/service/auth-guard.service";
import { FullWithSupportComponent } from "./layouts/full-with-support/full-with-support.component";
import { SharedModule } from "./shared.module";
import { BrowserModule } from "@angular/platform-browser";
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        AppFullComponent,
        AppBlankComponent,
        AppHeaderComponent,
        FooterComponent,
        FullWithSupportComponent,
        MenuComponent,
    ],
    imports: [
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: localStorage.getItem("locale") || "bm",
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        ServiceWorkerModule.register("/ngsw-worker.js", {
            enabled: environment.production,
        }),
        CustomPipeModule,
        NgxMaskModule.forRoot(),
    ],
    providers: [
        AuthService,
        AuthGuardService,
        { provide: MAT_DATE_LOCALE, useValue: "en-MY" },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
