import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { UserProfileInterface } from "../interface/user.interface";

@Injectable({
    providedIn: "root",
})
export class UserDataService {
    private basePath: string = `${environment.url}/api/MotorUser/`;

    constructor(private http: HttpClient) {}

    login(mobileNo: string, password: string) {
        return this.http.post(`${this.basePath}Login`, { mobileNo, password }, { responseType: "text" });
    }

    verifyRider(mobileNo: string, identityNo: string) {
        return this.http.post(`${this.basePath}VerifyUserICAndMobile`, {
            mobileNo,
            identityNo,
        });
    }

    registerRider(
        mobileNo: string,
        email: string,
        identityNo: string,
        password: string,
        address1: string,
        address2: string,
        postcode: string,
        city: string,
        state: string,
    ) {
        return this.http.post(`${this.basePath}Register`, {
            mobileNo,
            email,
            password,
            identityNo,
            address: address1,
            address2,
            postcode,
            city,
            state,
        });
    }

    verifyRegisterOtp(mobileNo: string, otp: string) {
        return this.http.post(`${this.basePath}VerifyOtp`, {
            mobileNo,
            otp,
        });
    }

    getMobileNoFromKey(key: string) {
        return this.http.get(`${this.basePath}GetMobileNo?key=${key}`, {
            responseType: "text",
        });
    }

    verifyOtpForResetPassword(mobileNo: string, otp: string) {
        return this.http.post(`${this.basePath}VerifyOtp`, {
            mobileNo,
            otp,
        });
    }

    sendOtpForActivate(mobileNo: string) {
        return this.http.post(`${this.basePath}SendOtpForActivate`, { mobileNo });
    }

    sendOtpForResetPassword(mobileNo: string) {
        return this.http.post(`${this.basePath}SendOtpForResetPassword`, {
            mobileNo,
        });
    }

    getProfileDetails() {
        return this.http.get<UserProfileInterface>(`${this.basePath}GetProfileDetails`);
    }

    editProfile(
        email: string,
        mobileNo: string,
        maritalStatus: string,
        address: string,
        address2: string,
        postcode: string,
        city: string,
        state: string,
    ) {
        return this.http.post(`${this.basePath}EditProfile`, {
            email,
            mobileNo,
            maritalStatus,
            address,
            address2,
            postcode,
            city,
            state,
        });
    }

    updatePassword(currentPassword: string, newPassword: string) {
        return this.http.post(`${this.basePath}ChangePassword`, {
            currentPassword,
            newPassword,
        });
    }

    resetPassword(mobileNo: string, password: string) {
        return this.http.post(`${this.basePath}ResetPassword`, {
            mobileNo,
            password,
        });
    }
}
