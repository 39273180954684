import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgOtpInputComponent, NgOtpInputConfig } from "ng-otp-input";
import { CountdownComponent, CountdownConfig, CountdownEvent } from "ngx-countdown";
import { NgxSpinnerService } from "ngx-spinner";
import { UserDataService } from "src/app/share/data-service/user.data.service";
import { AlertService } from "src/app/share/service/alert.service";

@Component({
    selector: "app-otp-dialog",
    templateUrl: "./otp-dialog.component.html",
    styleUrls: ["./otp-dialog.component.scss"],
})
export class OtpDialogComponent implements OnInit {
    @ViewChild("countdown") countdown: CountdownComponent;
    @ViewChild("otpInput") otpInput: NgOtpInputComponent;
    otp: string = "";
    otpExpired: boolean = false;
    otpInputConfig: NgOtpInputConfig = {
        length: 6,
        inputClass: "otp-input",
    };
    otpIsValid: boolean = false;
    otpIsVerified: boolean = false;
    userId: string = "";
    otpSent: boolean = false;
    config: CountdownConfig = { leftTime: 30, format: "mm:ss", notify: [5] };
    _data: { mobileNo: string; identityNo: string } = {
        mobileNo: "",
        identityNo: "",
    };

    constructor(
        private ngxSpinnerService: NgxSpinnerService,
        private _dialogRef: MatDialogRef<OtpDialogComponent>,
        private userDataService: UserDataService,
        private alertService: AlertService,
        @Inject(MAT_DIALOG_DATA)
        private data: { mobileNo: string; identityNo: string },
    ) {
        this._data = data;
    }

    ngOnInit(): void {}

    handeOtpChange(value: string): void {
        if (value.length === 6) {
            this.otpIsValid = true;
            this.otp = value;
        } else {
            this.otp = "";
        }
    }

    handleResendOTP() {
        //Check valid information
        this.otpExpired = false;
        this.otpIsValid = false;
        this.otpInput.setValue("");
        this.countdown.restart();
        this.handleGetOtp();
    }

    handleGetOtp() {
        this.otpSent = true;
        this.userDataService.verifyRider(this._data.mobileNo, this._data.identityNo).subscribe({
            next: (x) => {
                this.otpSent = true;
            },
            error: (err) => {
                console.log(err);
            },
        });
    }

    handleVerifyOTP() {
        this.ngxSpinnerService.show();
        this.userDataService.verifyRegisterOtp(this._data.mobileNo, this.otp).subscribe({
            next: (x) => {
                this.ngxSpinnerService.hide();
                if (x) {
                    this.otpIsVerified = true;
                    this._dialogRef.close(true);
                }
            },
            error: (err) => {
                this.alertService.openSnackBar("Invalid/Expired OTP");
                this.ngxSpinnerService.hide();
            },
        });
    }

    handleEvent(e: CountdownEvent) {
        if (e.action === "notify") {
        }
        if (e.action === "done") {
            this.otpExpired = true;
        }
    }
}
