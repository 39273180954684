import { HostListener } from "@angular/core";
import { Directive, Input } from "@angular/core";

@Directive({
    selector: "[callTo]",
})
export class CallToDirective {
    @Input("callTo") phone: string;

    @HostListener("click")
    onClick() {
        location.href = `tel:${this.phone}`;
    }
}
