<div class="footer">
    <div routerLink="/home" routerLinkActive="active" class="icon-text-wrapper">
        <mat-icon>home</mat-icon>
        <div class="icon-text">Home</div>
    </div>
    <div routerLinkActive="active" class="icon-text-wrapper">
        <mat-icon>list</mat-icon>
        <div class="icon-text">Activity</div>
    </div>
    <div routerLink="/claims/abc123" routerLinkActive="active" class="icon-text-wrapper">
        <mat-icon>money</mat-icon>
        <div class="icon-text">Claims</div>
    </div>
</div>
